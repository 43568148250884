import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  getDataLockService,
  searchProductService,
  updateLockService,
} from '../../../services/productService'

import HelpIcon from '@mui/icons-material/Help'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'

const PalletBlock = () => {
  const { userRoles, subCompany, nameUrl } = useContext(Context)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [hasSave, setHasSave] = useState(false)
  const [saveText, setSaveText] = useState('')
  const [saveStatus, setSaveStatus] = useState()

  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [justificationValue, setJustificationValue] = useState('')
  const [searching, setSearching] = useState(false)
  const [openJustification, setOpenJustification] = useState(false)
  const [statusServ, setStatusServ] = useState('Pronto')
  const [statusServColor, setStatusServColor] = useState('success')
  const [numberPallet, setNumberPallet] = useState('')
  const [lote, setLote] = useState('')
  const [messageJustification, setMessageJustification] = useState('')

  const [produtos, setProdutos] = useState([])
  const [produtosList, setProdutosList] = useState([])
  const [singleProd, setSingleProd] = useState()
  const [viewElearning, setViewElearning] = useState(false)

  const [loadingList, setLoadingList] = useState(false)

  const [permissionQuantityBoxDELETE, setPermissionQuantityBoxDELETE] = useState(false)

  const [updateList, setUpdateList] = useState([])

  const [saving, setSaving] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const addToList = async () => {
    const p = { ...singleProd }
    p.lote = lote
    p.numberPallet = numberPallet
    const repetido = updateList.find(x => x.productId === p.productId)
    const repetido2 = updateList.find(x => x.numberPallet === p.numberPallet)
    const repetido3 = updateList.find(x => x.lote === p.lote)
    if (repetido && repetido2 && repetido3) {
      setStatusServ('Produto já está na lista')
      setStatusServColor('warning')
      return
    } else {
      setLoadingList(true)
      setStatusServ('Adicionando produto...')
      setStatusServColor('info')

      const dados = {
        productId: p.productId,
        subCompanyId: subCompany[0].subCompanyId,
        lote: p.lote,
        numberPallet: p.numberPallet,
      }

      try {
        const checkBlocked = await getDataLockService(token, nameUrl, dados)
        if (checkBlocked.data.length > 0) {
          checkBlocked.data.map(c => {
            if (
              updateList.find(x => x.productId === c.productId) &&
              updateList.find(x => x.numberPallet === c.numberPallet) &&
              updateList.find(x => x.lote === c.lote)
            ) {
              return
            } else {
              setUpdateList(curr => [...curr, c])
            }
          })

          setStatusServ('Produto adicionado')
          setStatusServColor('success')
          setStatusServColor('success')
        } else {
          setStatusServ('Produto não encontrado')
          setStatusServColor('warning')
        }
        setLoadingList(false)
      } catch (error) {
        setStatusServ(error.response.data.message)
        setStatusServColor('error')
        setLoadingList(false)
      }
    }
  }

  const changeProd = id => {
    const prod = produtosList.find(x => x.productId === id)
    const finalProd = prod
    setSingleProd(finalProd)
  }

  const changeCheck = (index, box) => {
    const list = [...updateList]
    list[index].lockProduct = box
    setUpdateList(list)
  }

  const saveList = async () => {
    if (justificationValue === '') {
      setMessageJustification('Escreva uma justificativa')
      return
    }
    setSaving(true)
    setStatusServ('Salvando...')
    setStatusServColor('info')
    try {
      await Promise.all(
        updateList.map(async item => {
          const dados = {
            productItens: item.productItensId,
            Lock: item.lockProduct,
            Justification: justificationValue,
          }
          await updateLockService(token, nameUrl, dados)
        })
      )
      setSaving(false)
      setStatusServ(`Finalizado`)
      setStatusServColor('success')
      setUpdateList([])
    } catch (e) {
      setSaving(false)
      setStatusServ('Erro ao salvar lista')
      setStatusServColor('warning')
    }
  }

  useEffect(() => {
    setLoading(true)
  }, [])

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          setProdutosList(response.data.result)
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = () => {
    setHasSave(false)
  }

  return (
    <>
      <Dialog open={openJustification} onClose={handleClose}>
        <DialogTitle>Informe sua justificativa</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={justificationValue}
            error={!!messageJustification}
            helperText={messageJustification}
            onChange={e => setJustificationValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={saveList}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={hasSave}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={saveStatus} sx={{ width: '100%' }}>
            {saveText}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'PalletBlock'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Bloqueio de Palete/Lote</Typography>{' '}
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box
          sx={{ width: '100%', justifyContent: 'space-evenly' }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box sx={{ width: '100%' }}>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                disablePortal
                id="lista-produtos"
                options={produtos}
                loading={searching}
                loadingText="Procurando..."
                noOptionsText="Nenhum produto encontrado"
                isOptionEqualToValue={(option, value) => option.productId === value.value}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.productId}>
                      {option.label}
                    </li>
                  )
                }}
                renderInput={params => <TextField {...params} label="Procurar produto" />}
                onInputChange={(e, v) => {
                  setSearch(v)
                }}
                onChange={(e, v) => changeProd(v.value)}
              />
            </FormControl>
          </Box>
        </Box>
        {singleProd && (
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                value={statusServ}
                id="standard-basic"
                label="Status"
                variant="outlined"
                type="text"
                sx={{ width: '100%' }}
                color={statusServColor}
                focused
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={singleProd.name}
                id="standard-basic"
                label="Nome Produto"
                variant="standard"
                type="text"
                disabled
              />
            </Box>
          </Box>
        )}
        {singleProd && (
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={singleProd.referencia}
                id="standard-basic"
                label="Referência"
                variant="standard"
                type="text"
                disabled
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={singleProd.sku}
                id="standard-basic"
                label="SKU"
                variant="standard"
                type="text"
                disabled
              />
            </Box>
          </Box>
        )}
        {singleProd && (
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={lote}
                id="standard-basic"
                label="Lote"
                variant="standard"
                type="text"
                onChange={e => setLote(e.target.value)}
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={numberPallet}
                id="standard-basic"
                label="Número Pallet"
                variant="standard"
                type="text"
                onChange={e => setNumberPallet(e.target.value)}
              />
            </Box>
          </Box>
        )}
        {singleProd && (
          <Box
            sx={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              mt: 4,
            }}
          >
            <Box
              width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center', display: 'flex', gap: 2 }}
            >
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                type="button"
                onClick={addToList}
                disabled={loadingList}
              >
                {loadingList ? 'Adicionando...' : 'Adicionar a lista'}
              </Button>
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                type="button"
                onClick={() => setUpdateList([])}
                disabled={loadingList}
              >
                Limpar lista
              </Button>
            </Box>
          </Box>
        )}
        {updateList.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <button
                      type="button"
                      onClick={() => setOpenJustification(true)}
                      disabled={saving}
                      className="buttonDocs"
                      sx={{ justifyContent: 'center', fontSize: '1.2em' }}
                    >
                      Salvar
                    </button>
                  </StyledTableCell>
                  <StyledTableCell align="center">Nome Produto</StyledTableCell>
                  <StyledTableCell align="center">Referência</StyledTableCell>
                  <StyledTableCell align="center">Lote</StyledTableCell>
                  <StyledTableCell align="center">Pallet</StyledTableCell>
                  <StyledTableCell align="center">Local</StyledTableCell>
                  <StyledTableCell align="center">Data Validade</StyledTableCell>
                  <StyledTableCell align="center">Justificativa</StyledTableCell>
                  <StyledTableCell align="center">Bloqueado</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {updateList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                        {permissionQuantityBoxDELETE && (
                          <DeleteForeverIcon
                            onClick={() =>
                              setUpdateList(curr => curr.filter((v, i) => i !== index))
                            }
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                      <StyledTableCell align="center">{row.referencia}</StyledTableCell>
                      <StyledTableCell align="center">{row.lote}</StyledTableCell>
                      <StyledTableCell align="center">{row.numberPallet}</StyledTableCell>
                      <StyledTableCell align="center">{row.address}</StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(row.dataExpiration).toLocaleDateString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.justification}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Checkbox
                          checked={row.lockProduct}
                          onChange={e => changeCheck(index, e.target.checked)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={updateList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={saving}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PalletBlock
